import { useState, useContext } from 'react';

//styles
import styles from "./Search.module.css";

//External
import { Select, MenuItem, OutlinedInput, InputAdornment, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';


//hooks
import { useNavigate } from 'react-router-dom';
import { GetCertContext } from "../../hooks/useGetCertContext";

// icons
import { CircleAlert, Search, X } from "lucide-react";
import { useUserContext } from '../../contexts/UserContext';
import { Roles } from '../../utils/EnumRoles';

enum Filter {
  remoteMagic = 'MagicTool Remoto' as any,
  certWifi = 'Certificados Wi-Fi' as any,
  certBandWidth = 'Certificados Banda Larga' as any,
}

const SearchInput = () => {
  const [selectedQueryOption, setSelectedQueryOption] = useState<Filter>(Filter.certWifi);
  const [queryValue, setQueryValue] = useState<string>('');
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();

  const { loading } = useContext(GetCertContext);
  const { user, userHasRole } = useUserContext();

  const handleQueryApiRequest = async () => {
    // if (queryValue.length <= 11) {
    //     setError("Por favor verifique se a quantidade de caracteres está correta!")
    //     return
    // }

    navigate(`/servicos-produtos/${Filter[selectedQueryOption]}/${queryValue}`);
  };

  return (
    <Box width={'40em'}>
      <main className={styles.content}>
        <Select
          className={styles.select2}
          autoWidth
          value={selectedQueryOption}
          onChange={(e) => setSelectedQueryOption(e.target.value as Filter)}
        >
          {user && userHasRole([Roles.admin, Roles.avancado, Roles.suporte]) ?
            <MenuItem value={Filter.remoteMagic}>{Filter.remoteMagic}</MenuItem>
            :
            <></>
          }
          <MenuItem value={Filter.certWifi}>{Filter.certWifi}</MenuItem>
          <MenuItem value={Filter.certBandWidth}>{Filter.certBandWidth}</MenuItem>
        </Select>
        <OutlinedInput
          className={styles.query_input2}
          placeholder={`Busque por ${selectedQueryOption}`}
          value={queryValue}
          onChange={(e) => setQueryValue(e.target.value)}
          required
          endAdornment={
            <InputAdornment position="end" >
              {loading ?
                <IconButton
                  disabled
                  edge="end"
                >
                  <Search onClick={handleQueryApiRequest} />
                </IconButton>
                :
                <IconButton
                  edge="end"
                >
                  <Search onClick={handleQueryApiRequest} />
                </IconButton>
              }
            </InputAdornment>
          }
        />
      </main>
      {error &&
        <div className={styles.error_div}>
          <CircleAlert />
          <p className={styles.error}>{error}</p>
          <X onClick={() => setError("")} style={{ cursor: "pointer" }} />
        </div>
      }
    </Box>
  );
};

export default SearchInput;