import { Outlet, useNavigate, useParams } from "react-router-dom";

import styles from "./SubNavBar.module.css";

import { Home } from "lucide-react";
import SearchInput from "../Search/SearchInput";
import { useEffect } from "react";
import { Box } from "@mui/material";

const SubNavBar = () => {

  // vai vir uma url diferente
  let { filter, value, page } = useParams();
  const navigate = useNavigate();

  useEffect(() => {

  }, [filter, value]);

  return (
    <>
      <div className={styles.container2}>
        <nav >
          <Box display="flex" justifyContent={"space-between"} paddingBottom={'20px'}>
            {
              filter &&
              <ul className={styles.list_nav}>
                <li style={{ cursor: "pointer" }} onClick={() => navigate("/")} ><Home /> /</li>
                <li>Serviços e produtos /</li>
                <li>
                  {filter.includes("remote") ? "MagicTool Remoto /"
                    : filter.includes('Wifi') ? "Certificado Wi-Fi /"
                      : filter.includes("BandWidth") && "Certificado Banda Larga /"

                  }
                </li>
                {value &&
                  <li>{value}</li>
                }
              </ul>
            }
            {
              page && page.includes("dash") &&
              <ul className={styles.list_nav}>
                <li style={{ cursor: "pointer" }}><Home /> /</li>
                <li>Inicio</li>
              </ul>
            }
            <SearchInput />
          </Box>

        </nav>
      </div>
      <>
        <Outlet />
      </>
    </>
  );
};

export default SubNavBar;