import { IpLightListData } from "../interfaces/IPLightList";
import { WifiData } from "../interfaces/CertWifi";
import { ResetPasswordToken } from "../interfaces/ResetPasswordToken";
import { LoginUser } from "../interfaces/LoginUser";
import { UserInfoData } from "../interfaces/UserInfo";
import { PingResponse } from "../interfaces/PingResponse";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import ApiError from "../utils/ApiError";
import keycloak from "../config/keycloak";
import { Certificado } from "../interfaces/BandaLarga";
import { ProfileOltPsaResponse } from "../interfaces/ProfileOltPsaResponse";

const BASE_URL: string = process.env.REACT_APP_API_BASE_URL_SYS ? process.env.REACT_APP_API_BASE_URL_SYS : 'http://127.0.0.1:8000/api/v1/web'; // Replace with your API base URL
const MOCK_URL: string = "http://localhost:8080/api"; // Replace with your API base URL

// Helper function to make HTTP requests
async function request(url: string, method: string = 'GET', data: any = null, headers: any = {}, mock: boolean = false): Promise<any> {
  const access_token = keycloak.token;
  const authHeaders = access_token ? { Authorization: `Bearer ${access_token}` } : {};

  const config: AxiosRequestConfig = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...authHeaders,
    },
    url: `${mock ? MOCK_URL : BASE_URL}${url}`,
    data: data ? JSON.stringify(data) : undefined,
  };

  try {
    const response = await axios(config);

    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw new ApiError({ code: error.code, message: error.message, cause: error.cause, responseData: error.response?.data });
    }
    throw new Error(`API Error: ${error.message}`);
  }
}

export async function ProfileOltPsaRequest(data: { designador: string; }): Promise<ProfileOltPsaResponse> {
  return await request(`/certifications/independents/gps`, 'POST', data);
}