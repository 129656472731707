//CSS
import styles from "./Home.module.css";
import "react-multi-carousel/lib/styles.css";

//assets
import logo from "../../assets/svgs/logo.svg";

//components
import Footer from "../../components/Footer";
import SearchInput from "../../components/Search/SearchInput";
import AccountMenu from "../../components/AccountMenu";

//External
import { Box } from '@mui/material';

import { useUserContext } from "../../contexts/UserContext";

//hoks
import useAuthentication from "../../hooks/useAuthentication";


const Home = () => {

  const { isAuthenticated, user } = useUserContext();
  const { logout } = useAuthentication();

  return (
    <div className={styles.main}>
      <img src={logo} alt="logo" className={styles.logo} />
      <div className={styles.container}>
        <header className={styles.header}>
          <span className={styles.header_logo}>
            <img src={logo} alt="logo" />
            <h1>MagicTool</h1>
          </span>
          <p>Selecione o produto e faça uma busca pelo seu identificador.</p>
        </header>
        <Box display={"flex"} justifyContent={"center"}>
          <SearchInput />
        </Box>
      </div>
      <Footer style={{ color: "#fff", backgroundColor: "transparent" }} />
    </div>
  );
};

export default Home;
