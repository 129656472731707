import styles from './Navbar.module.css';
import logo from "../assets/images/Somente icone hd.png";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Typography, AppBar, Container, Toolbar, Box, IconButton, Menu, Button, Backdrop } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
//Context
import { useUserContext } from '../contexts/UserContext';
//Hooks
import useAuthentication from '../hooks/useAuthentication';
//Utils
import { Roles } from '../utils/EnumRoles';
//Components
import MenuDropdown from './MenuDropdown';
import AccountMenu from './AccountMenu';

//Interfaces
import { IMenuItem } from '../interfaces/MenuItem';

type NavPages = {
  displayName: string;
  uri?: string;
  subMenu?: IMenuItem[];
  allowedRoles?: string[];
};

// const serviceItems: IMenuItem[] = [
//   {
//     category: 'Certificações Mobile',
//     items: [
//       // { text: 'IP Light', link: '/ip-light', new: true },
//       { text: 'Serviços', link: '/servicos-produtos/BandWidth' },
//       { text: 'WiFi', link: '/servicos-produtos/Wifi' },
//     ],
//   },
//   {
//     category: 'Certificações Remotas',
//     items: [
//       { text: 'MagicTool Remoto', link: '/servicos-produtos/remoteMagic' }
//       // { text: 'Internet by Hac', link: '/hac' },
//     ],
//   },
// ];

const publicPages: NavPages[] = [{ displayName: 'Login', uri: "login" }, { displayName: 'Cadastre-se', uri: "/cadastro" }];
const protectedPages: NavPages[] = [
  // { displayName: "Inicio", uri: "/home-dash"},
  // { displayName: 'Serviços e Produtos', subMenu: serviceItems },
  { displayName: "Suporte", uri: "/suporte", allowedRoles: [Roles.admin, Roles.avancado, Roles.suporte] },
];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [pagesLoggedFiltered, setPagesLoggedFiltered] = useState<NavPages[]>(protectedPages);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { isAuthenticated, user, userHasRole } = useUserContext();

  const { logout } = useAuthentication();

  // const handleLogout = (): void => {
  //   logout(user);
  // };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const menuItemBuild = (itens: NavPages[]) => {
    return (
      itens.map((page, index) => !page.subMenu ? (
        <MenuItem key={index} onClick={handleCloseNavMenu} component={Link} to={page.uri!}>
          <Typography textAlign="center">{page.displayName}</Typography>
        </MenuItem>
      ) : (
        <MenuDropdown key={index} displayName={page.displayName} menuItems={page.subMenu} onMenuOpen={handleMenuOpen} onMenuClose={handleMenuClose} />
      ))
    );
  };

  useEffect(() => {
    //Filter the protectedPages list by the user perfil=
    if (isAuthenticated) {
      setPagesLoggedFiltered(protectedPages.filter((page) => {
        // Check if the page has no specified allowedRoles or if the user role is included in the allowedRoles array
        return !page.allowedRoles || userHasRole(page.allowedRoles);
      }));
    }
  }, [isAuthenticated]);

  return (
    <>
      <AppBar className={styles.nav} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            {/* Tela Menor */}
            <Typography
              variant="h6"
              noWrap
              component={Link} to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img className='logo_img' src={logo} alt="logomagic" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {isAuthenticated
                  ? menuItemBuild(pagesLoggedFiltered)
                  : menuItemBuild(publicPages)
                }
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            {/* Tela Maior */}
            <Typography
              style={{ borderBottom: "1px solid black" }}
              variant="h5"
              noWrap
              component={Link} to="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img className='logo_img' src={logo} alt="logomagic" />
            </Typography>
            <Box
              sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
            >
              {isAuthenticated ? pagesLoggedFiltered.map((page, index) => !page.subMenu ? (
                <Button
                  key={index}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'black', display: 'block' }}
                  component={Link} to={page.uri!}
                >
                  {page.displayName}
                </Button>
              ) : (
                <MenuDropdown key={index} displayName={page.displayName} menuItems={page.subMenu} onMenuOpen={handleMenuOpen} onMenuClose={handleMenuClose} />
              ))
                :
                publicPages.map((page, index) => (
                  <Button
                    key={index}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'black', display: 'block' }}
                    component={Link} to={page.uri!}
                  >
                    {page.displayName}
                  </Button>
                ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {isAuthenticated &&
                <div className={styles.user_profile_container}>
                  {user ? (
                    <AccountMenu userName={user.name} handleLogout={logout} />
                  ) : (
                    <AccountMenu userName="" handleLogout={logout} />
                  )}

                </div>
              }
            </Box>
          </Toolbar>
        </Container>
      </AppBar >
      <Toolbar />
      {/* Makes the elements bellow appbar with a background black with opacity */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isMenuOpen}
      />
    </>
  );
}
export default Navbar;
